@tailwind base;
@tailwind components;
@tailwind utilities;

/* // disable the dark mode */
/* @media (prefers-color-scheme: dark) { */
/*   body { */
/*     background-color: #000000; */
/*   } */
/* } */

@layer utilities {
  .overflow-hidden {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
}

::-webkit-scrollbar {
  width: 0%;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 4px;
  background-color: var(--background-white-white1);
}


.scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: var(--background-white-white1);
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
  background-color: var(--background-brand-secondary);
  width: 4px;
}

.ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Custom image styles */

.ProseMirror img {
  transition: filter 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &.ProseMirror-selectednode {
    outline: 3px solid #5abbf7;
    filter: brightness(90%);
  }
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Custom TODO list checkboxes – shoutout to this awesome tutorial: https://moderncss.dev/pure-css-custom-checkbox-style/ */

ul[data-type="taskList"] li > label {
  margin-right: 0.2rem;
  user-select: none;
}

@media screen and (max-width: 768px) {
  ul[data-type="taskList"] li > label {
    margin-right: 0.5rem;
  }
}

ul[data-type="taskList"] li > label input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 5px;
  border: 2px solid black;
  margin-right: 0.3rem;
  display: grid;
  place-content: center;

  &:hover {
    background-color: #f8f9fa;
  }

  &:active {
    background-color: #e9ecef;
  }

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em;
    transform-origin: center;
    clipPath: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }
}

ul[data-type="taskList"] li[data-checked="true"] > div > p {
  color: #a8a29e;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

@layer base {
  :root {

    --interaction-brand-primary: 27 52 69;
    --interaction-brand-primary-dark: 26 36 44;
    --interaction-brand-secondary: 74 153 135;
    --interaction-brand-secondary-dark: 31 109 92;
    --interaction-brand-white: 255 255 255;

    --typography-brand-primary: 27 52 69;
    --typography-brand-primary-dark: 26 36 44;
    --typography-brand-secondary: 74 153 135;
    --typography-brand-secondary-dark: 31 109 92;
    --typography-brand-white: 255 255 255;
    --typography-grey-grey1: 181 190 198;
    --typography-grey-grey2: 127 149 171;
    --typography-grey-grey3: 74 86 96;
    --typography-grey-grey4: 30 30 30;
    
    --border-brand-primary: 27 52 69;
    --border-brand-primary-dark: 26 36 44;
    --border-brand-secondary: 74 153 135;
    --border-brand-secondary-dark: 31 109 92;
    --border-brand-white-transparent: 255 255 255 0;
    --border-brand-white-white1: 255 255 255;
    --border-brand-white-white2: 250 250 250;
    --border-brand-white-white3: 240 242 244;
    --border-brand-white-white4: 232 235 236;
    --border-grey-grey1: 181 190 198;
    --border-grey-grey2: 127 149 171;
    --border-grey-grey3: 74 86 96;
    --border-grey-grey4: 30 30 30;
    
    --background-brand-primary: 27 52 69;
    --background-brand-primary-dark: 26 36 44;
    --background-brand-secondary: 74 153 135;
    --background-brand-secondary-dark: 31 109 92;
    --background-brand-white-transparent: 255 255 255 0;
    --background-brand-white-white1: 255 255 255;
    --background-brand-white-white2: 250 250 250;
    --background-brand-white-white3: 240 242 244;
    --background-brand-white-white4: 232 235 236;
    --background-grey-grey1: 181 190 198;
    --background-grey-grey2: 127 149 171;
    --background-grey-grey3: 74 86 96;
    --background-grey-grey4: 30 30 30;

    --vair-error: 177 46 38;

    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
 
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
 
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
 
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
 
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
 
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
 
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
 
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
 
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
 
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}